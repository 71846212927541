.sidebar {
  height: 100vh;
  width: 17%;
  background-color: #fff;
  z-index: 100;
  position: absolute;
  /* top: 15.5%; */
  /* transition: all 0.5s ease; */
  /* margin: 0px 25px; */
  border-radius: 15px;
}
.sidebar.close {
  width: 70px;
}
.sidebar .logo-details {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
}
.sidebar .logo-details i {
  font-size: 30px;
  color: #fff;
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
}
.sidebar .logo-details .logo_name {
  font-size: 22px;
  color: #fff;
  font-weight: 600;
  transition: 0.3s ease;
  transition-delay: 0.1s;
}
.sidebar.close .logo-details .logo_name {
  transition-delay: 0s;
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links {
  height: 100%;
  /* padding: 0 0 150px 0; */
  overflow: auto;
}
.sidebar.close .nav-links {
  overflow: visible;
}
.sidebar .nav-links::-webkit-scrollbar {
  display: none;
}
.sidebar .nav-links li {
  position: relative;
  list-style: none;
  /* transition: all 0.1s ease; */
  margin: 2px 10px;
  padding: 4px 0px;
}

.sidebar.open .nav-links li {
  position: relative;
  list-style: none;
  /* transition: all 0.1s ease; */
  margin: 10px 10px;
  padding: 4px 0px;
}
.sidebar .close .nav-links li {
  position: relative;
  list-style: none;
  /* transition: all 0.1s ease; */
  margin: 2px 10px;
  padding: 4px 0px;
}
.sidebar .nav-links li:hover {
  background: #003464;
  /* margin: 5px 10px; */
  border-radius: 10px 10px 0px 10px;
  padding: 4px 0px;
  color: #ffff;
}
.sidebar.close .nav-links li {
  position: relative;
  list-style: none;
  transition: all 0.3s ease;
  /* margin: 2px 5px; */
  padding: 4px 0px;
}
.sidebar.close .nav-links li:hover {
  background: #003464;
  /* margin: 5px 10px; */
  border-radius: 10px 10px 0px 10px;
  /* padding: 3px 0px; */
  color: #ffff;
}

.sidebar .nav-links li .icon-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar.close .nav-links li .icon-link {
  display: block;
}
.sidebar .nav-links li svg {
  /* height: 34px; */
  min-width: 50px;
  text-align: center;
  line-height: 50px;
  /* color: #fff; */
  font-size: 20px;
  cursor: pointer;
  transition: all 0.1s ease;
}
.sidebar .close .nav-links li svg {
  height: 30px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
  /* color: #fff; */
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.sidebar .nav-links li img {
  height: 30px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
  /* color: #fff; */
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.sidebar .nav-links li.showMenu i.arrow {
  transform: rotate(-180deg);
}
.sidebar.close .nav-links i.arrow {
  display: none;
}
.sidebar .nav-links li a {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.sidebar .nav-links li a .link_name {
  font-size: 15px;
  font-weight: 400;
  /* color: #fff; */
  transition: all 0.4s ease;
}
.sidebar.close .nav-links li a .link_name {
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links li .sub-menu {
  padding: 6px 6px 14px 80px;
  margin-top: -10px;
  background: #003366;
  display: none;
}
.sidebar .nav-links li.showMenu .sub-menu {
  display: block;
}
.sidebar .nav-links li .sub-menu a {
  color: #fff;
  font-size: 15px;
  padding: 5px 0;
  white-space: nowrap;
  opacity: 0.6;
  transition: all 0.3s ease;
}
.sidebar .nav-links li .sub-menu a:hover {
  opacity: 1;
}
.sidebar.close .nav-links li .sub-menu {
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  opacity: 0;
  display: block;
  pointer-events: none;
  transition: 0s;
}
.sidebar.close .nav-links li:hover .sub-menu {
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
  left: 73px;
  box-shadow:
    rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px,
    rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.sidebar .nav-links li .sub-menu .link_name {
  display: none;
}
.sidebar.close .nav-links li .sub-menu .link_name {
  font-size: 11px;
  opacity: 1;
  display: block;
  background-color: #003366;
}
.sidebar .nav-links li .sub-menu.blank {
  opacity: 1;
  pointer-events: auto;
  padding: 0px 9px 6px 9px;
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links li:hover .sub-menu.blank {
  top: 50%;
  transform: translateY(-50%);
  border-radius: 60px 5px 5px 56px;
}
.sidebar .profile-details {
  position: fixed;
  bottom: 0;
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1d1b31;
  padding: 12px 0;
  transition: all 0.5s ease;
}
.sidebar.close .profile-details {
  background: none;
}
.sidebar.close .profile-details {
  width: 78px;
}
.sidebar .profile-details .profile-content {
  display: flex;
  align-items: center;
}
.sidebar .profile-details img {
  height: 52px;
  width: 52px;
  object-fit: cover;
  border-radius: 16px;
  margin: 0 14px 0 12px;
  background: #1d1b31;
  transition: all 0.5s ease;
}
.sidebar.close .profile-details img {
  padding: 10px;
}
.sidebar .profile-details .profile_name,
.sidebar .profile-details .job {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
}
.sidebar.close .profile-details i,
.sidebar.close .profile-details .profile_name,
.sidebar.close .profile-details .job {
  display: none;
}
.sidebar .profile-details .job {
  font-size: 13px;
}
.home-section {
  position: fixed;
  background: #e4e9f7;
  height: 100vh;
  left: 260px;
  width: calc(100% - 260px);
  transition: all 0.5s ease;
}
.sidebar.close ~ .home-section {
  left: 78px;
  width: calc(100% - 78px);
}
.home-section .home-content {
  height: 60px;
  display: flex;
  align-items: center;
}
.home-section .home-content .bx-menu,
.home-section .home-content .text {
  color: #11101d;
  font-size: 35px;
}
.home-section .home-content .bx-menu {
  margin: 0 15px;
  cursor: pointer;
  height: 35px;
  width: 35px;
}
.home-section .home-content .text {
  font-size: 26px;
  font-weight: 600;
}
@media (max-width: 420px) {
  .sidebar.close .nav-links li .sub-menu {
    display: none;
  }
}
.menu-toggle {
  font-size: 30px;
  height: 30px;
  width: 30px;
}
.bx.bx-menu.menu-toggl {
  width: 30px;
}
.bx.bx-menu.menu-toggl {
  width: 30px;
  position: absolute;
  left: 60px;
  /* background: #ffa22c; */

  color: #fff;
}
.main-continor {
  box-shadow:
    rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px,
    rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px;
  /* border: 1px solid red; */
  border-radius: 15px;
  /* margin-top: 35px; */
  position: relative;
  padding: 14px 0px;
  height: 100%;
  /* left: 20px; */
}

.close .cross-icon-btn {
  display: none;
}
.plus-icon-btn {
  position: absolute;
  right: -17%;
  background: #003366;
  border-radius: 50%;
  top: 2%;
  cursor: pointer;
  z-index: 111;
  border: 1px solid #003366;
  box-shadow:
    rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px,
    rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.open .plus-icon-btn {
  display: none;
}

.sidebar .nav-links li:hover svg {
  fill: #fff;
  color: #fff;
}

.sub-menu-section-icon {
  color: #003366;
}
.sub-menu-section li a {
  padding-left: 10px;
  font-size: 14px;
}
.sidebar .open .sub-menu-section-outer:hover svg {
  color: #fff;
}
.close .sub-menu-section {
  display: none;
}
.delete-account-icon svg {
  fill: #003366;
}
/* -------------------menu svg icon----------------- */
.dashboard-menu-icon .icon-path {
  fill: #003366;
}

.dashboard-menu-icon:hover .icon-path,
.dashboard-menu-icon.selected .icon-path {
  fill: #fff;
}
.account-settings {
  font-size: 20px;
  font-weight: 700;
  color: #003366;
}
.open .cross-icon-btn {
  position: absolute;

  right: -6%;
  width: 26px;
  background: #003366;
  border-radius: 50%;
  top: 2%;
  cursor: pointer;
  z-index: 111;
  border: 1px solid #003366;
  box-shadow:
    rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px,
    rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.plus-icon-btn:hover {
  background-color: #ffa500;
}
.cross-icon-btn:hover {
  background-color: #ffa500;
}
.dashboardsection-image {
  background-color: #ffa500;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.dashboardsection-card {
  background-color: #fbfdff;
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  padding: 25px 10px;
  justify-content: space-around;
  box-shadow: rgb(255 193 7 / 43%) 0px 7px 16px 0px;
}
